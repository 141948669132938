import React from "react";
import { Link } from "gatsby";
import { IoPeople, IoTimer, IoCheckmarkCircle } from "react-icons/io5";

import Image from "../../../components/image";
import { Container, Wrapper, Row, Box } from "../../../components/util";
import {
  CallToAction,
  FeaturePageHeader,
  CountrySpecificContent,
} from "../../../components/site";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";

const Onboarding = (props) => (
  <Layout>
    <Seo
      title="Onboarding | Karmly Feature"
      description="Cover off health & safety requirements and other compliance tasks for contractors. Manage contracts, work permits and other important documents in Karmly."
      pathname={props.location.pathname}
    />
    <FeaturePageHeader
      title="Onboarding"
      description="Get your contractors to enter information or complete a set of tasks when they start a new placement. Automate compliance and induction tasks and employee document management."
      image="Invoxy_Onboarding_Header.png"
    />
    <Container>
      <Wrapper>
        <Row className="-textCenter" stackGap={40}>
          <Box size={33}>
            <h3>
              <IoPeople />
              <br />
              Customise by Client
            </h3>
            <p>
              Create standard onboarding flows, or customised templates for
              specific clients or placements.
            </p>
          </Box>
          <Box size={33}>
            <h3>
              <IoTimer />
              <br />
              Save Time
            </h3>
            <p>
              Let your contractors do the admin by entering their own personal,
              tax and banking details.
            </p>
          </Box>
          <Box size={33}>
            <h3>
              <IoCheckmarkCircle />
              <br />
              Tick the Boxes
            </h3>
            <p>
              Manage all compliance tasks online so contractors can turn up on
              day one ready to work.
            </p>
          </Box>
        </Row>
        <hr />
        <Row stackGap={60} alignCenter mobileReverse>
          <Box size={50}>
            <Image
              filename="InvoxyFeature_Onboarding_Steps.png"
              alt="Custom Onboarding Steps"
            />
          </Box>
          <Box size={50}>
            <h3>Easy Template Builder</h3>
            <p>
              Create onboarding templates containing a range of forms, documents
              or videos. Automate new user induction and ensure all contracts,
              health & safety requirements and employment documents are
              completed before the contract starts. Choose which steps are
              required and must be completed before the contractor can submit
              timesheets or get paid.
            </p>
          </Box>
        </Row>
        <Row stackGap={60} alignCenter>
          <Box size={50}>
            <h3>Contractor Self Service</h3>
            <p>
              Getting contractors to enter their own bank account numbers, tax
              codes and other personal details reduces your admin while
              maintaining oversight of this key process. Contractors can
              complete onboarding from the{" "}
              <Link to="/recruitment/features/mobile-timesheets">Karmly Mobile app</Link>.
            </p>
          </Box>
          <Box size={50}>
            <Image
              filename="InvoxyFeature_Onboarding_Form.png"
              alt="Onboarding Form"
            />
          </Box>
        </Row>
        <Row stackGap={60} alignCenter mobileReverse>
          <Box size={50}>
            <Image
              filename="InvoxyFeature_Signed.png"
              alt="Built in e-signing for Onboarding"
            />
          </Box>
          <Box size={50}>
            <h3>Employee Documents</h3>
            <p>
              Document management is easy – get contractors to e-sign documents
              during onboarding. Documents and files submitted during onboarding
              are saved on the contractor’s record and you can upload other docs
              at any time.
            </p>
          </Box>
        </Row>
        <Row stackGap={60} alignCenter>
          <Box size={50}>
            <h3>Full Visibility</h3>
            <p>
              You’ll receive an in-app notification every time a contractor
              completes the onboarding process. All onboarding submissions
              require admin approval so you can double check everything is in
              order before the contractor first gets paid.
            </p>
          </Box>
          <Box size={50}>
            <Image
              filename="InvoxyFeature_Onboarding_EmployeeV.png"
              alt="Notifications on Completion of Onboarding"
            />
          </Box>
        </Row>
        <CountrySpecificContent forCountry={["Australia"]}>
          <Row stackGap={60} alignCenter mobileReverse>
            <Box size={50}>
              <Image
                filename="InvoxyFeature_Onboarding_TFN.png"
                alt="ATO Compliance"
              />
            </Box>
            <Box size={50}>
              <h3>ATO Compliance</h3>
              <p>
                Contractors can submit their Tax File Number (TFN) Declaration
                as part of onboarding. Karmly will send the TFN details to your{" "}
                <Link to="/recruitment/integrations">integrated payroll system</Link> where
                you can lodge them electronically to the ATO.
              </p>
            </Box>
          </Row>
        </CountrySpecificContent>
      </Wrapper>
    </Container>
    <CallToAction />
  </Layout>
);

export default Onboarding;
